import * as angular from 'angular';
import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { AuthZeroService } from '@services/auth-zero.service';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { AccountModel, PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class ToolbarController extends BaseController {
  public accountRoleName: string;
  public userFullName: string;
  public isSsoUser = false;
  public isVisibleDataHub = false;
  public errorCount = 0;

  private _http: angular.IHttpService;
  private _interval: angular.IIntervalService;
  private _mdSidenav: angular.material.ISidenavService;
  private _rootScope: angular.IRootScopeService;
  private _authZeroService: AuthZeroService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;
  private _permissionsService: PermissionService;

  private alertRefreshMilliSeconds = SWANConstants.alertRefreshMinutes * 60 * 1000;

  constructor(
    $http: angular.IHttpService,
    $interval: angular.IIntervalService,
    $mdSidenav: angular.material.ISidenavService,
    $rootScope: angular.IRootScopeService,
    $scope: angular.IScope,
    AuthZeroService: AuthZeroService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._interval = $interval;
    this._mdSidenav = $mdSidenav;
    this._rootScope = $rootScope;
    this._authZeroService = AuthZeroService;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;
    this._permissionsService = PermissionService;

    this.accountRoleName = this.permissionService.currentAccount?.role;
    this.userFullName = this.permissionService.fullName;
    this.isSsoUser = AuthZeroService.isSsoUser;
    this.isVisibleDataHub = !!AppSettings.app.dataHubUrl;

    this.scope.$on('accountChanged', () => {
      this.accountRoleName = this.permissionService.currentAccount?.role;
    });

    this.scope.$on('refreshAlertCount', () => {
      this.getAlertCounts();
    });
  }

  get hasPendingRequests(): boolean {
    return this._localStorageService.hasPendingRequests();
  }

  get accountSearchText(): string {
    return this.permissionService.accountSearchText;
  }

  set accountSearchText(value: string) {
    this.permissionService.accountSearchText = value;
  }

  $onInit() {
    this._interval(
      () => {
        this.getAlertCounts();
      },
      this.alertRefreshMilliSeconds,
      0,
      true,
    );
  }

  public async changeAccount(targetAccount: AccountModel): Promise<void> {
    await this._permissionsService.changeAccount(targetAccount);
  }

  public toggleSidenav(sidenavId: string) {
    this._mdSidenav(sidenavId).toggle();
  }

  public showQuickPanel() {
    this._rootScope.$broadcast('quick-panel-sidenav');
  }

  public changePassword() {
    this._http.put(CommonHelper.getApiUrl('users/changePassword'), {}).then((res) => {
      if (res) {
        const result = res.data as fuse.httpResultDto;
        if (result.succeeded) {
          this._languageService.info('PROJ.SENDING_EMAIL_PASSWORD_RESET');
        } else {
          this._languageService.whoops();
        }
      }
    });
  }

  public openDataHub() {
    window.open(AppSettings.app.dataHubUrl, '_blank');
  }

  public openKnowledgeBase() {
    window.open(AppSettings.app.knowledgeBaseUrl, '_blank');
  }

  public openEmail() {
    const name = this.account.name.replace(' ', '%20');
    const messageBody = this._languageService.instant('PROJ.TOOLBAR.MESSAGE_BODY');
    const messageSubject = this._languageService.instant('PROJ.TOOLBAR.MESSAGE_SUBJECT', { account: name });
    window.open('mailto:' + AppSettings.app.helpDeskEmail + '?subject=' + messageSubject + '&body=' + messageBody, '_blank');
  }

  public openTicketSubmit() {
    window.open(AppSettings.app.ticketSubmitUrl, '_blank');
  }

  public openTermsOfService() {
    window.open(AppSettings.app.termsOfServiceUrl, '_blank');
  }

  public openPrivacyPolicy() {
    window.open(AppSettings.app.privacyPolicyUrl, '_blank');
  }

  public openFullStory() {
    window.open(AppSettings.app.fullStoryUrl, '_blank');
  }

  public logout() {
    this._authZeroService.logout();
  }

  private getAlertCounts() {
    if (this.apf.hasAlertView) {
      this._http.get(CommonHelper.getApiUrl('users/getAlertCounts')).then((result) => {
        const counts = result.data as fuse.toolbarAlertCountDto;
        this.errorCount = counts.errorCount;
      },
      () => {});
    }
  }
}

angular.module('app.toolbar').controller('ToolbarController', ToolbarController);
